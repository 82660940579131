import { pluralize } from '@/Utils/TextUtility';
import { convertToMajorValue } from '@/Utils/NumberUtility';
import Formatter from '@/Utils/Formatter';
import { projectPermissions } from '@/Models';
import { hasProjectPermissionCurried } from '@/Utils/RoleUtility';

export const performanceMarketingCampaignsTypes = {
    'crowdfunding': 'crowdfunding',
    'upcoming': 'upcoming',
};

export const locationRestrictedTypes = {
    unlimited: 'Unlimited',
    notAvailableIn: 'NotAvailableIn',
    availableIn: 'AvailableIn',
};

export const performanceMarketingWizardSteps = {
    info: 'info',
    budget: 'budget',
};

export const optimizationTargetTypes = {
    purchase: 'Purchase',
    addToWishlist: 'AddToWishlist',
};

export const budgetSpendingStrategyTypes = {
    linearSpread: 'LinearSpread',
    crowdfundingCampaign: 'CrowdfundingCampaign',
};

export const feeHandlingStrategyTypes = {
    staticPercentageStrategy: 'StaticPercentageStrategy',
    gamefoundTrialStrategy: 'GamefoundTrialStrategy',
};

export const campaignStatusTypes = {
    active: 'Active',
    paused: 'Paused',
};

export const assetGroupStatusTypes = {
    active: 'Active',
    paused: 'Paused',
    draft: 'Draft',
};

export const entityEffectiveStatusTypes = {
    draft: 'Draft',
    active: 'Active',
    paused: 'Paused',
    scheduled: 'Scheduled',
    completed: 'Completed',
    inactive: 'Inactive',
};

export const assetTypes = {
    image: 'Image',
    text: 'Text',
};

export const assetReviewStatus = {
    pendingReview: 'PendingReview',
    approved: 'Approved',
    rejected: 'Rejected',
};

export const paymentStatus = {
    pending: 'Pending',
    paid: 'Paid',
    failed: 'Failed',
};

export const assetReviewStatusDisplayName = {
    [assetReviewStatus.pendingReview]: 'Awaiting Review',
    [assetReviewStatus.approved]: 'Approved',
    [assetReviewStatus.rejected]: 'Rejected',
};

export const textAssetTypes = {
    'headline': 'Headline',
    'primaryText': 'PrimaryText',
    'description': 'Description',
};

export const issuesCodeTypes = {
    ASSETS_PENDING_REVIEW: 'ASSETS_PENDING_REVIEW',
    ASSETS_REJECTED: 'ASSETS_REJECTED',
    LACK_OF_ASSETS: 'LACK_OF_ASSETS',
    NO_ADS: 'NO_ADS',
    LOW_FUNDS: 'LOW_FUNDS',
    NO_FUNDS: 'NO_FUNDS',
    ASSET_GROUP_READY_TO_ACTIVATE: 'ASSET_GROUP_READY_TO_ACTIVATE',
};

export function buildFundsEnoughForLabel(daysRemaining) {
    if (daysRemaining === null) {
        return '-';
    }
    if (daysRemaining < 1) {
        return 'less than a day';
    }
    else if (daysRemaining >= 7) {
        return 'more than 7 days';
    }

    return `${daysRemaining} ${pluralize(daysRemaining, 'day', 'days') }`;
}

export const wizardStepsGuard = (to, from, next) => {
    if (performanceMarketingCampaignsTypes[to.params.type]) {
        next();
    }
    else {
        next({
            name: 'performance-marketing-campaigns-select',
            replace: true,
            params: to.params,
        });
    }
};

export function hasUnfinishedFirstAssetGroup(assetGroups) {
    const hasOneOrLessAssetGroups = assetGroups.length <= 1;
    const firstAd = assetGroups[0];
    if (firstAd) {
        return firstAd.configuredStatus === assetGroupStatusTypes.draft;
    }
    return hasOneOrLessAssetGroups;
}

const buildStatisticItem = ({ title, value, enabled, action, tooltip = null }) => {
    const { route = null, label = null } = action || {};
    return { title, value, actionLabel: label, actionRoute: route, tooltip, enabled };
};

export const buildStatisticsItemRemainingFunds = (fundsRemaining, campaignId, hasAction = true, enabled = true) => {
    const formattedValue =  Formatter.format(convertToMajorValue(fundsRemaining), 'C2', '€');
    const route = { name: 'performance-marketing-funds',  params: { campaignId } };
    return buildStatisticItem({
        title: 'remaining funds',
        value: formattedValue,
        action: hasAction ? { route, label: 'show funds' } : null,
        tooltip: 'The money available for marketing campaign. When out of funds, ads automatically stop.',
        enabled,
    });
};

export const buildStatisticsItemRemainingAddFunds = (fundsRemaining, campaignId, enabled = true) => {
    const formattedValue =  Formatter.format(convertToMajorValue(fundsRemaining), 'C2', '€');
    const route = { name: 'performance-marketing-payment-add',  params: { campaignId } };
    return buildStatisticItem({
        title: 'remaining funds',
        value: formattedValue,
        action: { route, label: 'add funds' },
        tooltip: 'The money available for marketing campaign. When out of funds, ads automatically stop.',
        enabled,
    });
};

export const buildStatisticsItemFundsEnoughFor = (daysRemaining, enabled = true) => {
    const formattedValue = buildFundsEnoughForLabel(daysRemaining);
    return buildStatisticItem({
        title: 'funds enough for',
        value: formattedValue,
        tooltip: 'Estimated number of days the top-up amount will be sufficient to run ads, based on the current campaign spending dynamics.',
        enabled,
    });
};

export const buildStatisticsItemAdfoundFees = (feeAmount, campaignId, isFreeTrial, enabled = true) => {
    const formattedValue = Formatter.format(convertToMajorValue(feeAmount), 'C2', '€');
    const route = { name: 'performance-marketing-billing', params: { campaignId } };
    const label = 'view billing';
    let tooltipText = 'Fees are calculated based on Adfound\'s performance. The final cost will be displayed after the campaign in the Billing section.';

    if (isFreeTrial) {
        tooltipText += ' Free trial funds used for running your ads are excluded from fee calculations.';
    }

    return buildStatisticItem({
        title: 'Adfound fees',
        value: formattedValue,
        action: { route, label },
        tooltip: tooltipText,
        enabled,
    });
};

export const buildStatisticItemOverallSpending = (totalSpent, enabled = true) => {
    const formattedValue = Formatter.format(convertToMajorValue(totalSpent), 'C2', '€');
    return buildStatisticItem({
        title: 'overall spending',
        value: formattedValue,
        tooltip: 'The total amount of funds spent on ads.',
        enabled,
    });
};

export const buildStatisticsItemConversions = (conversionCount, enabled = true) => {
    const formattedValue = Formatter.format(conversionCount, 'N0');
    return buildStatisticItem({
        title: 'number of conversions',
        value: formattedValue,
        tooltip: 'Number of followers gained from your ads.',
        enabled,
    });
};

export const buildStatisticsItemCostPerFollower = (costPerConversion, enabled = true) => {
    const formattedValue = Formatter.format(convertToMajorValue(costPerConversion), 'C2', '€');
    return buildStatisticItem({
        title: 'cost per follower',
        value: formattedValue,
        tooltip: 'The amount of money spent to gain each new follower from your ads so far.',
        enabled,
    });
};

export const buildStatisticsItemOverallRevenue = (totalValue, enabled = true) => {
    const formattedValue = Formatter.format(convertToMajorValue(totalValue), 'C2', '€');
    return buildStatisticItem({
        title: 'overall revenue',
        value: formattedValue,
        tooltip: 'The total income generated from your marketing campaign without ad spend or fees.',
        enabled,
    });
};

export const buildStatisticsItemROAS = (roas, enabled = true) => {
    const formattedValue = Formatter.format(roas, 'N2');
    return buildStatisticItem({
        title: 'overall ROAS',
        value: formattedValue,
        tooltip: 'Return on Advertising Spend (ROAS) shows how much money you earn for each dollar or euro spent on advertising.',
        enabled,
    });
};

export const buildStatisticsItemTotalFees = (feeAmount, enabled = true) => {
    const formattedValue = Formatter.format(convertToMajorValue(feeAmount), 'C2', '€');
    return buildStatisticItem({
        title: 'total fees',
        value: formattedValue,
        tooltip: 'The total fees to pay for your entire campaign. The final amount will be calculated and displayed at the end of the campaign when the invoice is ready for payment.',
        enabled,
    });
};

export const buildStatisticsItemPaidTotal = (totalSpent, enabled = true) => {
    const formattedValue = Formatter.format(convertToMajorValue(totalSpent), 'C2', '€');
    return buildStatisticItem({
        title: 'paid total',
        value: formattedValue,
        tooltip: 'The total amount of fees that have already been paid. It\'s the sum of all payments with the Paid status.',
        enabled,
    });
};

export const buildStatisticsItemToPay = (toPay, enabled = true) => {
    const formattedValue = Formatter.format(convertToMajorValue(toPay), 'C2', '€');
    return buildStatisticItem({
        title: 'to pay',
        value: formattedValue,
        tooltip: 'The total amount of fees that await payment. It\'s the sum of all payments with the Invoiced status.',
        enabled,
    });
};

export const buildStatisticsItemDeclaredBudget = (maxSpent, enabled = true) => {
    const formattedValue = Formatter.format(convertToMajorValue(maxSpent), 'C0', '€');
    return buildStatisticItem({
        title: 'declared budget',
        value: formattedValue,
        tooltip: 'This is the planned overall budget for the campaign, used to shape the ad strategy and spending pace throughout the campaign duration.',
        enabled,
    });
};

export const budgetExceededMessage = 'After the top-up, the campaign funds will exceed the declared budget limit. Proceeding will update the budget to prevent unspent funds and adjust the campaign dynamics accordingly. If you do not wish to extend the budget, cancel and modify the top-up amount.';

export const buildStatisticsItem = {
    overallSpending: buildStatisticItemOverallSpending,
    conversions: buildStatisticsItemConversions,
    costPerFollower: buildStatisticsItemCostPerFollower,
    overallRevenue: buildStatisticsItemOverallRevenue,
    ROAS: buildStatisticsItemROAS,
    totalFees: buildStatisticsItemTotalFees,
    paidTotal: buildStatisticsItemPaidTotal,
    toPay: buildStatisticsItemToPay,
    remainingFunds: buildStatisticsItemRemainingFunds,
    remainingAddFunds: buildStatisticsItemRemainingAddFunds,
    fundsEnoughFor: buildStatisticsItemFundsEnoughFor,
    adfoundFees: buildStatisticsItemAdfoundFees,
    declaredBudget: buildStatisticsItemDeclaredBudget,
};

export const questions = [
    {
        id: 1,
        title: 'What should my budget be?',
        description: 'Adfound allows campaigns with a budget of minimum 25 EUR per day. Once you input the basic details into the system, it will guide you and suggest appropriate budgets for the phase you’re in. The final decision regarding budget is yours.',
        isCollapsed: true,
    },
    {
        id: 2,
        title: 'Is there a spending limit?',
        description: 'We recommend spending as much as makes sense and for as long as it remains profitable. If you’re satisfied with the ROAS (Return On Advertising Spend), consider increasing your investment. If you notice ROAS decreasing, it might be the right time to reduce spending. Always ensure your budget covers at least a few days of campaign activity.',
        isCollapsed: true,
    },
    {
        id: 3,
        title: 'How long the campaign should be?',
        description: 'The system allows you to launch a campaign for as little as one hour. However, it is recommended to run the campaign for at least three days to allow the learning phase to complete and optimize performance.',
        isCollapsed: true,
    },
    {
        id: 4,
        title: 'Is there a guaranteed ROAS?',
        description: 'Adfound uses a highly refined audience database to ensure your ads reach the right people. However, whether they choose to support your project depends on how appealing your project is to them. For this reason, we cannot guarantee a specific ROAS for your campaign.',
        isCollapsed: true,
    },
    {
        id: 5,
        title: 'What do I need to prepare for the campaign?',
        description: 'Provide Adfound with a selection of graphics and text proposals that reflect your project’s vision. Using these assets, Adfound will create optimized ad variations and manage your campaign for you.',
        isCollapsed: true,
    },
    {
        id: 6,
        title: 'What if I want to stop the ads, increase the budget, etc.?',
        description: 'You can set spending limits during the initial setup and adjust them at any time.',
        isCollapsed: true,
    },
    {
        id: 7,
        title: 'How much does it cost to work with Adfound?',
        description: 'Adfound fee is 5% of the money the ads bring. You will be charged at the end of your marketing campaign, based on the total revenue generated through Adfound. Campaigns in the "Upcoming" phase are not charged. You can monitor your spending and fees via the dashboard, with data synchronized periodically.',
        isCollapsed: true,
    },
    {
        id: 8,
        title: 'How do you determine which funds were brought by Adfound?',
        description: 'For both generating interest and revenue for your project, Adfound uses 7-day click and 1-day view attribution from Meta system.',
        isCollapsed: true,
    },
    {
        id: 9,
        title: 'Do I need a business account in Meta?',
        description: 'You don’t need to have Meta account to start running ads with Adfound, your ads will be displayed on our established accounts.',
        isCollapsed: true,
    },
    {
        id: 10,
        title: 'Do I need to set up any tracking tools or codes?',
        description: 'You don’t need to connect any tracking codes or tools. Once you launch your campaign, Adfound will handle all the tracking and measurement for you.',
        isCollapsed: true,
    },
];

export const benefits = [
    {
        title: 'Reach the right audience',
        icon: 'users',
        description:
            'With years of experience engaging millions of gamers, Adfound guarantees your ads reach the most relevant audience.',
    },
    {
        title: 'Invest Smarter',
        icon: 'money-bill-trend-up',
        description:
            'Adfound constantly optimizes your campaigns, ensuring that funds are allocated only to ads that drive attention and value to your project.',
    },
    {
        title: 'Save time and effort',
        icon: 'watch',
        description:
            'Set up your ads in just a few clicks using an advertising account with a long-standing, trusted reputation.',
    },
    {
        title: 'Maximize Performance',
        icon: 'analytics',
        description:
            'Simply provide your graphics and copy, and Adfound will run automated tests to identify and invest heavily in the highest-performing combinations.',
    },
    {
        title: 'Maintain control',
        icon: 'sliders-h',
        description:
            'Spend only what’s right for your campaign. Pause or boost your ads whenever needed.',
    },

    {
        title: 'Monitor results',
        icon: 'gauge-max',
        description:
            'Track your campaign’s performance with our user-friendly dashboard, offering real-time insights into key metrics.',
    },
];

export const hasAccessToPerformanceMarketing = (user, creatorID, projectID) => {
    const { managePerformanceMarketingIntegration } = projectPermissions;
    const hasProjectPermission = hasProjectPermissionCurried(
        user,
        creatorID,
        projectID,
    );
    return hasProjectPermission(managePerformanceMarketingIntegration);
};