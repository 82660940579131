import CrowdfundingAddonCardList from './CrowdfundingAddonCardList.vue';
import CrowdfundingRewardCardList from './CrowdfundingRewardCardList.vue';
import ProjectSummary from './ProjectSummary/ProjectSummary.vue';
import MiniWizardManager from './MiniWizard/MiniWizardManager.vue';
import ProjectCommentsStreams from './ProjectCommentsStreams.vue';
import ProjectNavigation from './ProjectNavigation.vue';
import ProjectTestMode from './ProjectTestMode/ProjectTestMode.vue';
import ProjectUpdates from './ProjectUpdates.vue';
import ProjectUpdate from './ProjectUpdate.vue';
import ProjectStretchGoals from './ProjectStretchGoals.vue';
import ProjectProductCardList from './ProjectProductCardList.vue';
import ProjectRecommendations from './Recommendations/ProjectRecommendations.vue';
import ProjectSlider from './ProjectSlider.vue';
import ProjectUserPledges from './ProjectUserPledges.vue';
import WithoutRewardSection from './WithoutRewardSection.vue';
import ProjectSectionContent from './Sections/ProjectSectionContent.vue';
import ProjectSectionStory from './Sections/ProjectSectionStory.vue';
import ProjectTitle from '@/Components/Projects/Sections/ProjectTitle.vue';
import ProjectSectionAddons from './Sections/ProjectSectionAddons.vue';
import ProjectSectionRewards from '@/Components/Projects/Sections/ProjectSectionRewards.vue';
import StoreProductCardList from '@/Components/Projects/Store/StoreProductCardList.vue';
import StoreProductCategoriesList from '@/Components/Projects/Store/StoreProductCategoriesList.vue';
import StoreContent from '@/Components/Projects/Store/StoreContent.vue';
import LegalEntityInformation from '@/Components/Projects/LegalEntityInformation.vue';
import LegacyProjectUserPledges from '@/Components/Legacy/LegacyProjectUserPledges.vue';

export default {
    CrowdfundingAddonCardList,
    CrowdfundingRewardCardList,
    ProjectSummary,
    MiniWizardManager,
    ProjectCommentsStreams,
    ProjectUpdates,
    ProjectUpdate,
    ProjectNavigation,
    ProjectTestMode,
    ProjectStretchGoals,
    ProjectProductCardList,
    ProjectSlider,
    ProjectUserPledges,
    WithoutRewardSection,
    ProjectRecommendations,
    ProjectSectionContent,
    ProjectSectionStory,
    ProjectTitle,
    ProjectSectionAddons,
    ProjectSectionRewards,
    StoreProductCardList,
    StoreProductCategoriesList,
    LegalEntityInformation,
    StoreContent,
    LegacyProjectUserPledges,
};
