var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClasses }, [
    _c(
      "div",
      { class: _vm.slotWrapperClasses },
      [_vm._t("default", null, null, { isStuck: _vm.isStuck })],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }