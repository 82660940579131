var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isInitialized
    ? _c(
        "mini-wizard-manager",
        _vm._b({}, "mini-wizard-manager", _vm.miniWizardModel, false)
      )
    : _c("skeleton-mini-wizard")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }