var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-project-mini-wizard-wrapper" }, [
    _c(
      "div",
      { staticClass: "gfu-project-mini-wizard _fo" },
      [
        _c(
          "detect-overflow",
          { staticClass: "gfu-project-mini-wizard__content" },
          [
            _c(
              "div",
              {
                staticClass:
                  "_pos-r _bgc--white _rounded--common gfu-skeleton--has-animation",
              },
              [
                _c(
                  "div",
                  { staticClass: "_pt-3 _px-3" },
                  [_c("skeleton-image")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_flexcol _pa-3 _gap-2" },
                  [
                    _c("skeleton-line"),
                    _vm._v(" "),
                    _c("skeleton-line"),
                    _vm._v(" "),
                    _c("skeleton-button", { staticClass: "_mb-6" }),
                    _vm._v(" "),
                    _c("skeleton-button"),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }