var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "scrollTarget", class: ["gfu-comment-item", _vm.elementClasses] },
    [
      _c(
        "div",
        { staticClass: "gfu-media" },
        [
          _c(
            "comment-user-avatar",
            _vm._b(
              { attrs: { "is-breakpoint-large": _vm.isBreakpointLarge } },
              "comment-user-avatar",
              { author: _vm.author },
              false
            )
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-media__body _pl-2" },
            [
              _c(
                "div",
                { staticClass: "_flex _ai-c" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "_flexwrap _ai-fe _col-gap-1",
                      attrs: {
                        "data-qa": `project-comment-pinned:${_vm.isPinned}`,
                      },
                    },
                    [
                      _c(
                        "comment-user-profile",
                        _vm._b(
                          {
                            attrs: {
                              "is-breakpoint-large": _vm.isBreakpointLarge,
                              "data-qa": "project-comment-header:UserProfile",
                            },
                          },
                          "comment-user-profile",
                          {
                            author: _vm.author,
                            isGamefoundStaff: _vm.isGamefoundStaff,
                            isCreator: _vm.isCreator,
                          },
                          false
                        )
                      ),
                      _vm._v(" "),
                      _vm.isAuthorVerified
                        ? _c(
                            "icon-base-label",
                            _vm._b(
                              {
                                attrs: {
                                  "data-qa": _vm.verifiedLabelModel.dataQa,
                                },
                              },
                              "icon-base-label",
                              _vm.verifiedLabelModel,
                              false
                            )
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "_tc--light gfu-bt--caption",
                          attrs: { "data-qa": "project-comment-header:date" },
                        },
                        [_vm._v(_vm._s(_vm.commentDateText))]
                      ),
                      _vm._v(" "),
                      _vm.isEdited
                        ? _c(
                            "tooltip",
                            {
                              staticClass: "_lh-1",
                              attrs: { text: _vm.editedTooltipText },
                            },
                            [
                              _c("base-icon", {
                                staticClass: "_tc--light",
                                attrs: { name: "info" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isPinned
                    ? _c("base-icon", {
                        staticClass: "_ml-a",
                        attrs: { name: "thumbtack" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasUrlName
                ? _c(
                    "comment-user-profile-actions",
                    _vm._b(
                      {},
                      "comment-user-profile-actions",
                      { author: _vm.author },
                      false
                    )
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasLabel
                ? _c(
                    "div",
                    { staticClass: "gfu-comments-labels" },
                    _vm._l(_vm.userLabels, function (label) {
                      return _c(
                        "icon-base-label",
                        _vm._b(
                          {
                            key: label.name,
                            attrs: { "data-qa": label.dataQa },
                          },
                          "icon-base-label",
                          label,
                          false
                        )
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isEditing
                ? _c("project-comment-field", {
                    ref: "edit",
                    staticClass: "_mt-3",
                    attrs: { mode: "edit", message: _vm.text },
                    on: { submit: _vm.onEditSubmit, cancel: _vm.onCancel },
                  })
                : _c("div", {
                    ref: "content",
                    staticClass: "gfu-comment-item__content",
                    attrs: { "data-qa": "project-comment-content" },
                    domProps: { innerHTML: _vm._s(_vm.formattedText) },
                    on: { click: _vm.onContentClick },
                  }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "_flex" },
                [
                  _vm.isProjectCommentReplyVisible
                    ? _c("project-comment-reply", {
                        staticClass: "_mr-1",
                        on: {
                          reply: function ($event) {
                            return _vm.$emit("reply", {
                              commentThreadID: _vm.commentThreadID,
                              commentID: _vm.commentID,
                            })
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isUserAuthenticated
                    ? _c(
                        "project-comment-actions",
                        _vm._g(
                          {
                            staticClass: "_mr-1",
                            attrs: {
                              "comment-thread-i-d": _vm.commentThreadID,
                              "comment-i-d": _vm.commentID,
                              "parent-i-d": _vm.parentID,
                              "pinned-at": _vm.pinnedAt,
                              "has-children": _vm.hasChildren,
                              hidden: _vm.hidden,
                              "is-editing": _vm.isEditing,
                              "has-pin-comment-action": _vm.hasPinCommentAction,
                              "has-report-and-hide-action":
                                _vm.hasReportAndHideAction,
                              "is-own-comment": _vm.isOwnComment,
                              "is-reported-by-creator": _vm.isReportedByCreator,
                              "allow-creator-hiding-comments":
                                _vm.allowCreatorHidingComments,
                              "has-user-admin-url": _vm.hasUserAdminUrl,
                              "admin-url": _vm.author.adminUrl,
                              "data-qa": `project-comment-hidden:${_vm.hidden}`,
                            },
                            on: { edit: _vm.onToggleEdit },
                          },
                          _vm.$listeners
                        )
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hidden
                    ? _c("like-action", {
                        attrs: {
                          "likes-count": _vm.likesCount,
                          "is-liked": _vm.isLiked,
                          enabled: _vm.isUserAuthenticated,
                          "data-qa": `project-comment-liked:${_vm.isLiked}`,
                        },
                        on: {
                          like: function ($event) {
                            return _vm.$emit("like", {
                              commentID: _vm.commentID,
                            })
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }