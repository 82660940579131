var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "box",
    { staticClass: "_flexcol _gap-2 _tc--base", attrs: { nested: "" } },
    [
      _c(
        "div",
        {
          staticClass: "_flex _jc-sb _cur-p _ai-c",
          on: { click: _vm.onToggle },
        },
        [
          _c("h4", { staticClass: "gfu-bt gfu-bt--b1 _ma-0 _fw-n" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.highlightedTitle) },
            }),
          ]),
          _vm._v(" "),
          _c("base-icon", {
            staticClass: "_tc--accent",
            attrs: { size: "2x", name: _vm.iconName },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isCollapsed,
              expression: "!isCollapsed",
            },
          ],
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.highlightedDescription) },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }