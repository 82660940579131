var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-view",
    [
      _c("footer-navigation", {
        attrs: {
          "current-page": _vm.Resources.Layout.PerformanceMarketingLink,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-performance-marketing-hero" },
        [
          _c(
            "h2",
            {
              staticClass:
                "gfu-hd gfu-hd--d2 gfu-hd--decorative _tc--accent-inverse _mb-4",
            },
            [_vm._v("\n            Marketing with Adfound\n        ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-become-creator-hero__content _mb-6" }, [
            _vm._v(
              "\n            Elite marketing tool accessible to everyone."
            ),
            _c("br"),
            _vm._v(
              "\n            An easy-to-use yet powerful marketing tool that allows you to run ad campaigns with the\n            lowest commission and the best targeting, ensuring maximum impact for your project.\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "processing-button",
            {
              attrs: { "is-processing": _vm.isProcessing, theme: "accent" },
              on: { click: _vm.onAction },
            },
            [_vm._v("\n            Try Adfound for free\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gfu-layout-wrapper gfu-layout-wrapper--narrow _py-6" },
        [
          _c("performance-marketing-start-box", {
            attrs: { "is-processing": _vm.isProcessing },
            on: { action: _vm.onAction },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }