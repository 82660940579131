var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-description" },
    [
      _vm.isTableOfContentsLoaded
        ? _c("table-of-contents")
        : _c("skeleton-table-of-contents"),
      _vm._v(" "),
      _c("project-contents-section", {
        attrs: {
          component: _vm.ProjectSectionRewards,
          model: _vm.projectSectionRewardsModel,
          layout: _vm.listLayoutTypes.column,
        },
      }),
      _vm._v(" "),
      _c("project-contents-section", {
        attrs: {
          component: _vm.ProjectSectionAddons,
          model: _vm.projectSectionAddonsModel,
          layout: _vm.listLayoutTypes.grid,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-project-description-section" }, [
        _c(
          "div",
          {
            staticClass:
              "gfu-layout-wrapper gfu-layout-wrapper--extranarrow gfu-4of5--l gfu-3of5--xl",
          },
          [
            _c("project-recommendations", {
              attrs: {
                quantity: 12,
                source: "rewards",
                "exclude-project-ids": _vm.excludeProjectIds,
                "data-qa": "rewards-view:Recommendations",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("mini-wizard-manager-with-loading", {
        attrs: { "mini-wizard-model": _vm.miniWizardModel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }