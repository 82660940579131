var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flexcol _gap-1" },
    _vm._l(_vm.items, function (item) {
      return _c("category-box", {
        key: item.url,
        attrs: {
          url: item.url,
          type: _vm.categoryBoxTypes.linear,
          "data-qa": item.qa,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "_flexcol _jc-c" },
                    [
                      _c(
                        "span",
                        { staticClass: "_fw-eb _tc--accent-inverse" },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                      _vm._v(" "),
                      _c("base-icon", {
                        staticClass:
                          "gfu-category-box__icon gfu-category-box-icon--linear",
                        attrs: {
                          name: item.icon.name,
                          size: "3x",
                          "icon-namespace": item.icon.namespace,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }