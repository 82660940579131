var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-bar gfu-layout-bar--bottom" }, [
    _c(
      "div",
      { staticClass: "gfu-project-bottom-bar _ai-st" },
      [
        _c(
          "skeleton-wrapper",
          { staticClass: "_flex _pt-2 _px-2 _pb-1 _gap-6 _bgc--white" },
          [
            _c(
              "div",
              { staticClass: "_flexcol _grow-1 _gap-1" },
              [
                _c("skeleton-line"),
                _vm._v(" "),
                _c("skeleton-line", { attrs: { height: 23 } }),
              ],
              1
            ),
            _vm._v(" "),
            _c("skeleton-button", { staticClass: "_grow-1" }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }