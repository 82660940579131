import { defineStore } from 'pinia';

export const useMobileMenuStore = defineStore({
    id: 'mobileMenu',
    state: () => ({
        navigationItems: [],
        isSearchVisible: false,
    }),
    actions: {
        addNavigationItem(item) {
            this.navigationItems.push(item);
        },
        setNavigationItems(items = []) {
            this.navigationItems = items;
        },
        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
        },
    },
});