var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPWA
    ? _c(
        "div",
        { staticClass: "gfu-top-navigation-bar js-top-navigation-bar" },
        [
          _vm.isBackButtonVisible
            ? _c("base-icon", {
                staticClass: "_pa-2",
                attrs: { tag: "button", size: "lg", name: "chevron-left" },
                on: { click: _vm.goBack },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "_ml-a" },
            [
              _vm._t("default", function () {
                return [
                  _vm.projectId
                    ? _c(
                        "share-action",
                        _vm._b(
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ onShare }) {
                                    return [
                                      _c("base-icon", {
                                        staticClass: "_pa-2",
                                        attrs: {
                                          tag: "button",
                                          name: _vm.shareIcon,
                                          size: "lg",
                                        },
                                        on: { click: onShare },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3173807145
                            ),
                          },
                          "share-action",
                          _vm.sharePayload,
                          false
                        )
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }