var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-layout__main gfu-layout__main--extranarrow" },
    [
      _c("h1", { staticClass: "gfu-hd gfu-hd--h1 _mt-6 _mb-5" }, [
        _vm._v(_vm._s(_vm.Resources.Profile.SectionFollowersHeader)),
      ]),
      _vm._v(" "),
      _vm.hasPagedItems
        ? _c(
            "div",
            { staticClass: "gfu-card-list gfu-card-list--wrap _-mt-3" },
            _vm._l(_vm.pagedItems, function (item) {
              return _c(
                "div",
                {
                  key: item.urlName,
                  staticClass:
                    "gfu-card-list__item gfu-1of1 gfu-1of3--l gfu-1of3--xl",
                  attrs: { "data-qa": `user-card-urlname:${item.urlName}` },
                },
                [
                  _c(
                    "user-profile-card",
                    _vm._b({}, "user-profile-card", item, false)
                  ),
                ],
                1
              )
            }),
            0
          )
        : _c(
            "box",
            [
              _c(
                "empty-state",
                {
                  attrs: {
                    title: _vm.Resources.Profile.EmptyStateFollowersTitle,
                    "data-qa": "user-profile:EmptyState",
                  },
                },
                [
                  _c("div", { staticClass: "_mt-3" }, [
                    _vm._v(
                      _vm._s(_vm.Resources.Profile.EmptyStateFollowersParagraph)
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c("load-more-trigger", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasMorePagedItems,
            expression: "hasMorePagedItems",
          },
        ],
        on: { "load-more": _vm.onLoadMore },
      }),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }