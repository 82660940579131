var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-table-of-contents" }, [
    _c("div", { staticClass: "gfu-table-of-contents__wrapper" }, [
      _c(
        "div",
        { staticClass: "_flexcol _gap-2 _ai-fe" },
        _vm._l(_vm.lines, function (line, i) {
          return _c("skeleton-line", {
            key: i,
            staticClass: "gfu-skeleton--has-animation _pos-r",
            attrs: { "width-percent": line },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }