// Breakpoints
export const breakpoint = {
    s: 480,
    m: 768,
    l: 992,
    xl: 1344,
    xxl: 1600,
};

// Button types
export const buttonThemeTypes = {
    default: '',
    primary: 'gfu-btn--primary',
    primaryAlt: 'gfu-btn--primary-alt',
    light: 'gfu-btn--light',
    lightInverse: 'gfu-btn--light-inverse',
    lightAlt: 'gfu-btn--light-alt',
    secondaryInverse: 'gfu-btn--secondary-inverse',
    secondaryAlt: 'gfu-btn--secondary-alt',
    accent: 'gfu-btn--accent',
    lightOutline: 'gfu-btn--light-outline',
    lightFlat: 'gfu-btn--light-flat',
    disabled: 'gfu-btn--disabled',
    disabledAlt: 'gfu-btn--disabled-alt',
    gray: 'gfu-btn--gray',
    grayAlt: 'gfu-btn--gray-alt',
    success: 'gfu-btn--success',
    danger: 'gfu-btn--danger',
    transparentInverse: 'gfu-btn--transparent-inverse',
    searchPrimary: 'gfu-btn--search-primary',
    searchSecondary: 'gfu-btn--search-secondary',
    outline: 'gfu-btn--outline',
    white: 'gfu-btn--white',
};

export const buttonSizeTypes = {
    default: '',
    nano: 'gfu-btn--nano',
    micro: 'gfu-btn--micro',
    large: 'gfu-btn--large',
};

export const buttonWidthTypes = {
    default: '',
    compact: 'gfu-btn--compact',
    tight: 'gfu-btn--tight',
    wide: 'gfu-btn--wide',
    narrow: 'gfu-btn--narrow',
};

export const buttonDisplayTypes = {
    default: '',
    block: 'gfu-btn--block',
    fixed: 'gfu-btn--fixed',
};

export const buttonShadowTypes = {
    default: '',
    none: 'gfu-btn--no-shadow',
    tight: 'gfu-btn--box-shadow-tight',
};

export const buttonShapeTypes = {
    hard: 'gfu-btn--hard',
    soft: 'gfu-btn--soft',
    common: 'gfu-btn--common',
    circle: 'gfu-btn--circle',
    default: '',
};

export const buttonWeightTypes = {
    default: '',
    thin: 'gfu-btn--thin',
};

export const buttonContentWidthTypes = {
    normal: '',
    wide: 'gfu-btn--wide',
    narrow: 'gfu-btn--narrow',
};

export const buttonTextTransformType = {
    none: '',
    lowercase: 'gfu-btn--lowercase',
    uppercase: 'gfu-btn--uppercase',
};

export const floatDirections = {
    left: '_fl',
    right: '_fr',
    initial: '',
};

export const textAligns = {
    left: '_tal',
    right: '_tar',
    center: '_tac',
};

// Icons sizes
export const iconSize = {
    big: 'gfu-icon--big',
    small: 'gfu-icon--small',
    tiny: 'gfu-icon--tiny',
    micro: 'gfu-icon--micro',
};

// Progress circle themes
export const progressCircleThemes = {
    accent: 'gfu-progress-circle--accent',
};

// icon position in buttons

export const iconPositions = {
    left: '',
    right: 'gfu-btn__icon--right',
    pullLeft: '_fl',
};

// icon prefixes

export const iconNamespaces = {
    fa: '_fa',
    ga: '_ga',
    brands: '_fa-brands',
};

export const textTransformOptions = {
    lower: '_ttl',
    upper: '_ttu',
    none: '',
};

// label types
export const labelThemes = {
    primary: 'gfu-label--primary',
    empty: 'gfu-label--empty',
    accent: 'gfu-label--accent',
    success: 'gfu-label--success',
    error: 'gfu-label--error',
    info: 'gfu-label--info',
    infoLight: 'gfu-label--info-light',
    light: 'gfu-label--light',
    warning: 'gfu-label--warning',
    suggestion: 'gfu-label--suggestion',
    default: 'gfu-label--default',
    disabled: 'gfu-label--disabled',
    accentLight: 'gfu-label--accent-light',
    primaryLight: 'gfu-label--primary-light',
    labelDark: 'gfu-label--label-dark',
};

export const labelShapes = {
    standard: '',
    badge: 'gfu-label--badge',
};

// balloon types
export const balloonDirectionClass = {
    up: 'gfu-balloon--up',
    down: 'gfu-balloon--down',
};

export const balloonPositionClass = {
    center: 'gfu-balloon--center',
    left: 'gfu-balloon--left',
    right: 'gfu-balloon--right',
};

export const balloonThemeClass = {
    default: '',
    inverse: 'gfu-balloon--inverse',
    error: 'gfu-balloon--error',
};

export const balloonSizeClass = {
    default: '',
    compact: 'gfu-balloon--compact',
    narrow: 'gfu-balloon--narrow',
    wide: 'gfu-balloon--wide',
};

export const iconSizes = {
    default: '',
    xs: '_fa--xs',
    sm: '_fa--sm',
    lg: '_fa--lg',
    '2x': '_fa--2x',
    '3x': '_fa--3x',
    '4x': '_fa--4x',
    '5x': '_fa--5x',
};

export const avatarSizes = {
    default: '36',
    xs: '48',
    sm: '64',
    lg: '128',
};

// BaseSummaryList types
export const summaryListSizes = {
    default: '',
    small: 'gfu-summary-list-wrapper--small',
    large: 'gfu-summary-list-wrapper--large',
};

// circleIconThemes
export const circleIconThemes = {
    info: 'gfu-circle-icon--info',
    success: 'gfu-circle-icon--success',
    warning: 'gfu-circle-icon--warning',
    error: 'gfu-circle-icon--error',
    disabled: 'gfu-circle-icon--disabled',
};

export const circleIconSizes = {
    default: 'gfu-circle-icon--size-default',
    large: 'gfu-circle-icon--size-large',
};

export const projectCardPhaseThemes = {
    preview: '_tc--primary',
    inactive: '_tc--lighter',
    active: '_tc--accent',
};

// base thumb sizes
export const thumbSizes = {
    small: 'gfu-thumb--small',
    medium: 'gfu-thumb--medium',
    large: 'gfu-thumb--large',
    big: 'gfu-thumb--big',
};

// table cell themes
export const tableCellThemes = {
    bad: 'gfu-table__cell--bad',
    good: 'gfu-table__cell--good',
};
