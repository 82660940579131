var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("display-currency-value", {
    attrs: {
      tag: _vm.tag,
      "reverse-tooltip-position": _vm.reverseTooltipPosition,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "content",
          fn: function ({ displayCurrency }) {
            return [
              _vm._t("effective-price", null, {
                effectivePrice: _vm.getFormattedPrice(
                  _vm.effectivePrice /
                    displayCurrency.relativeFactorWithCommission,
                  displayCurrency.symbol
                ),
              }),
            ]
          },
        },
        {
          key: "tooltip",
          fn: function () {
            return [_vm._t("tooltip")]
          },
          proxy: true,
        },
        {
          key: "tooltip-content",
          fn: function () {
            return [
              _c(
                "span",
                [
                  _c(
                    "localize",
                    {
                      attrs: {
                        resource:
                          _vm.Resources.ProjectHome
                            .ProjectApproximateCurrencyConversionTooltip,
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { "format-key": "0" } },
                        [
                          _c("span", { staticClass: "_whs-nw" }, [
                            _vm._v(_vm._s(_vm.formattedEffectivePrice)),
                          ]),
                          _vm._v(" "),
                          _vm.isDiscounted
                            ? [
                                _c("s", { staticClass: "_td-lt" }, [
                                  _vm._v(_vm._s(_vm.formattedPrice)),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "default",
          fn: function () {
            return [
              _vm._t("effective-price", null, {
                effectivePrice: _vm.formattedEffectivePrice,
              }),
              _vm.isDiscounted
                ? _vm._t("price", null, { price: _vm.formattedPrice })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        _vm.isDiscounted
          ? {
              key: "content-alt",
              fn: function ({ displayCurrency }) {
                return [
                  _vm._t("price", null, {
                    price: _vm.getFormattedPrice(
                      _vm.price / displayCurrency.relativeFactorWithCommission,
                      displayCurrency.symbol
                    ),
                  }),
                ]
              },
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }