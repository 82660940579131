var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-contents-section" },
    [
      _vm.isInitialized
        ? _c(
            _vm.component,
            _vm._b({ tag: "component" }, "component", _vm.model, false)
          )
        : _c(
            "div",
            { staticClass: "gfu-project-description-section" },
            [_c("skeleton-products-list", { attrs: { layout: _vm.layout } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }