var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-layout__main gfu-layout__main--extranarrow" },
    [
      _c("h1", { staticClass: "gfu-hd gfu-hd--h1 _mt-6 _mb-5" }, [
        _vm._v(_vm._s(_vm.Resources.Profile.SectionProjectsHeader)),
      ]),
      _vm._v(" "),
      _vm.hasPagedItems
        ? _c(
            "div",
            { staticClass: "gfu-card-list gfu-card-list--wrap _-mt-3" },
            _vm._l(_vm.pagedItems, function (project) {
              return _c(
                "div",
                {
                  key: project.projectID,
                  staticClass:
                    "gfu-card-list__item gfu-1of1 gfu-1of2--m gfu-1of3--l",
                },
                [
                  _c(
                    "project-card",
                    _vm._b(
                      { attrs: { "show-labels": true } },
                      "project-card",
                      project,
                      false
                    )
                  ),
                ],
                1
              )
            }),
            0
          )
        : _c(
            "box",
            [
              _c(
                "empty-state",
                {
                  attrs: {
                    title: _vm.Resources.Profile.EmptyStateProjectsTitle,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "_mt-3",
                      attrs: { "data-qa": "user-profile:EmptyState" },
                    },
                    [_vm._v(_vm._s(_vm.emptyStateMessage))]
                  ),
                  _vm._v(" "),
                  _vm.isViewingOwnProfile
                    ? _c(
                        "base-button",
                        {
                          staticClass: "_mt-3",
                          attrs: {
                            tag: "a",
                            href: _vm.searchUrl,
                            theme: "accent",
                            "data-qa": "user-profile-projects:Explore",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Profile
                                .EmptyStateProjectsExploreAction
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c("load-more-trigger", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasMorePagedItems,
            expression: "hasMorePagedItems",
          },
        ],
        on: { "load-more": _vm.onLoadMore },
      }),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }