var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": "",
        width: _vm.modalWidth,
        "show-action-close": true,
        "navigation-class-list": _vm.modalNavigationClassList,
      },
      on: { "action-close": _vm.close },
    },
    [
      _c("product-comparison-table", {
        attrs: { "columns-data": _vm.columnsData, "rows-data": _vm.rowsData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }