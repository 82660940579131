var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "skeleton-wrapper",
    {
      staticClass:
        "gfu-layout-wrapper gfu-layout-wrapper--extranarrow gfu-4of5--l gfu-3of5--xl",
      attrs: { "has-animation": false },
    },
    [
      _c("skeleton-header", { staticClass: "_mb-4" }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.skeletonCardsContainerClass },
        [
          _vm._l(_vm.cardsCount, function (i) {
            return [
              _c(_vm.skeletonComponent, {
                key: i,
                tag: "component",
                class: _vm.skeletonCardClass,
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }