var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_pa-3 _tac" },
    [
      _c("p", { staticClass: "_mt-0" }, [
        _vm._v(_vm._s(_vm.Resources.Layout.WebPushUnaothorizedUserParagraph)),
      ]),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "_mt-3",
          attrs: { tag: "a", theme: "accent", href: _vm.loginUrl },
        },
        [_vm._v(_vm._s(_vm.Resources.Account.LogInButton))]
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Account.SignUpCallToActionIntroParagraph) +
              "\n        "
          ),
          _c(
            "base-link",
            {
              staticClass: "_tc--accent",
              attrs: { url: _vm.createAccountUrl },
            },
            [_vm._v(_vm._s(_vm.Resources.Comments.SignUpLink))]
          ),
          _vm._v(".\n    "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }