import ServiceBase from './ServiceBase';

export default class ProjectContentsService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    getAddons(projectID) {
        return this.request('get', 'projectContents/getAddons', { projectID });
    }

    getMiniWizardModel(projectID) {
        return this.request('get', 'projectContents/getMiniWizardModel', { projectID });
    }

    getRewards(projectID) {
        return this.request('get', 'projectContents/getRewards', { projectID });
    }
}
