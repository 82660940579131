var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: _vm.model.title,
        "is-processing": _vm.isProcessing,
        "is-visible": _vm.isVisible,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.model.type === "question"
                ? [
                    _vm.hasDeclineLabel
                      ? _c(
                          "a",
                          {
                            staticClass: "gfu-link gfu-link--accent _ttl",
                            attrs: { href: "", "data-qa": "modal-button:No" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.cancel.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.getDeclineLabel))]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "gfu-link gfu-link--accent _ttl",
                            attrs: { href: "", "data-qa": "modal-button:No" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.cancel.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.Resources.Common.ModalNoButton))]
                        ),
                    _vm._v(" "),
                    _vm.hasAcceptLavel
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "gfu-btn gfu-btn--accent gfu-btn--compact gfu-btn--soft gfu-btn--wide",
                            attrs: {
                              small: false,
                              enabled: !_vm.isProcessing,
                              "data-qa": "modal-button:Yes",
                            },
                            on: { click: _vm.close },
                          },
                          [_vm._v(_vm._s(_vm.getAcceptLabel))]
                        )
                      : _c(
                          "button",
                          {
                            staticClass:
                              "gfu-btn gfu-btn--accent gfu-btn--compact gfu-btn--soft gfu-btn--wide",
                            attrs: {
                              small: false,
                              enabled: !_vm.isProcessing,
                              "data-qa": "modal-button:Yes",
                            },
                            on: { click: _vm.close },
                          },
                          [_vm._v(_vm._s(_vm.Resources.Common.ModalYesButton))]
                        ),
                  ]
                : [
                    _c(
                      "button",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--accent gfu-btn--compact gfu-btn--soft gfu-btn--wide",
                        attrs: {
                          small: false,
                          enabled: !_vm.isProcessing,
                          "data-qa": "modal-button:Ok",
                        },
                        on: { click: _vm.close },
                      },
                      [_vm._v(_vm._s(_vm.Resources.Common.ModalOkButton))]
                    ),
                  ],
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.model.text
        ? _c("span", { attrs: { "data-qa": "message-box-text" } }, [
            _vm._v(_vm._s(_vm.model.text)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }