var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-project-description-section gfu-project-description-section--regular gfu-layout-wrapper gfu-layout-wrapper--extranarrow gfu-4of5--l gfu-3of5--xl _pb-4",
      attrs: { "data-qa": `project-description-section:${_vm.sectionID}` },
    },
    [
      !_vm.hasParentCategoryWithProducts
        ? [
            _c("div", { staticClass: "gfu-heading--top-divider _mb-1" }),
            _vm._v(" "),
            _vm.hasAddonOnlyPledgesNotAllowedInfo
              ? _c("div", { staticClass: "gfu-bt gfu-bt--b2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.Resources.ProjectHome.AddonsRestrictionSubheader
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.categoriesProps, function (category, index) {
        return _c(
          "div",
          { key: category.categoryId },
          [
            _c(
              category.component,
              {
                ref: "addonsSubCategoryAnchors",
                refInFor: true,
                tag: "component",
                class: category.class,
                attrs: {
                  "data-qa": `project-description-section-title:${category.name}`,
                },
              },
              [
                !category.isChildCategory
                  ? _c("span", [_vm._v(_vm._s(category.name))])
                  : _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(category.parentName) +
                          "\n                "
                      ),
                      _c("span", { staticClass: "_tc--accent _fw-l" }, [
                        _vm._v(" » "),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(category.name))]),
                    ]),
                _vm._v(" "),
                category.infoText
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "gfu-heading__icon gfu-heading__icon--primary gfu-heading__icon--small",
                      },
                      [
                        _c("tooltip", {
                          attrs: {
                            text: category.infoText,
                            "icon-name": "info",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.hasAddonOnlyPledgesNotAllowedInfo && index === 0
              ? _c("div", { staticClass: "gfu-bt gfu-bt--b2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.Resources.ProjectHome.AddonsRestrictionSubheader
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("optional-text", {
              attrs: {
                content: category.description,
                "is-article": true,
                "is-rich-text": true,
                "data-qa": "project-section-addons:AddonsMoreInfo",
              },
            }),
            _vm._v(" "),
            _c("crowdfunding-addon-card-list", {
              attrs: {
                products: category.products,
                "checkout-url": _vm.checkoutUrl,
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }