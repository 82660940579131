var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-search-form",
        {
          "is-visible": _vm.overlayIsVisible,
          "gfu-search-form--with-results": _vm.isProcessing || _vm.hasResults,
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "gfu-search-form__overlay",
          attrs: { "data-qa": "navbar-search-form" },
        },
        [
          _c(
            "form",
            {
              ref: "form",
              staticClass: "gfu-search-form__fields gfu-search-form-fields",
              class: {
                "gfu-search-form-fields--with-close-button": _vm.isFocused,
                "gfu-search-form-fields--focused": _vm.isFocused,
              },
              attrs: { method: "get", action: _vm.formAction },
            },
            [
              _c(
                "div",
                { staticClass: "gfu-search-form-fields__field gfu-field" },
                [
                  _c("button", {
                    staticClass:
                      "gfu-search-form-fields__search-button gfu-btn _fa _fa--search",
                    attrs: { "data-qa": "search-form-button:Search" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.onInputEnterKeyPress.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    ref: "input",
                    staticClass:
                      "gfu-field__input gfu-field__input--soft gfu-search-form-fields__input",
                    attrs: {
                      placeholder: _vm.Resources.Layout.SearchFormPlaceholder,
                      type: "text",
                      name: "term",
                      autocomplete: "off",
                      "data-qa": "search-form:Input",
                    },
                    domProps: { value: _vm.formModel.term },
                    on: {
                      input: _vm.handleInput,
                      keydown: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.onInputEnterKeyPress.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          )
                            return null
                          return _vm.abortSearch.apply(null, arguments)
                        },
                      ],
                      focus: _vm.onInputFocus,
                      blur: _vm.onInputBlur,
                    },
                  }),
                  _vm._v(" "),
                  _c("button", {
                    staticClass:
                      "gfu-search-form-fields__close-button gfu-btn _fa _fa--times",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.abortSearch.apply(null, arguments)
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-search-form__results gfu-search-form-results",
              class: {
                "gfu-search-form__results--visible":
                  _vm.isProcessing || _vm.hasResults,
              },
            },
            [
              _c("progress-overlay", {
                attrs: { visible: _vm.isProcessing, small: true },
              }),
              _vm._v(" "),
              _vm.hasResults
                ? _c("div", [
                    !_vm.projects.totalItemCount
                      ? _c(
                          "div",
                          {
                            staticClass: "gfu-search-form-results__no-results",
                            attrs: { "data-qa": "search-form:EmptyState" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.Resources.Layout.SearchFormEmptyState
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.projects.totalItemCount
                      ? _c(
                          "div",
                          {
                            staticClass: "gfu-search-form-results__group",
                            attrs: { "data-qa": "search-form:Results" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "gfu-search-form-results__heading",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.Resources.Layout
                                        .SearchFormResultsProjectsCountLabel
                                    ) +
                                    " "
                                ),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.projects.totalItemCount)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.projects.pagedItems, function (project) {
                              return _c("search-form-item", {
                                key: `project-${project.projectID}`,
                                staticClass: "_mb-2",
                                attrs: {
                                  url: project.projectPageUrl,
                                  title: project.name,
                                  subtitle: project.creator.name,
                                  "thumb-image-url": project.thumbImageUrl,
                                  "data-qa": `search-form-results-projects:${project.projectID}`,
                                },
                              })
                            }),
                            _vm._v(" "),
                            _vm.projects.totalPageCount > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-search-form-results__more",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "gfu-link gfu-link--accent",
                                        attrs: { href: "" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.submitForm.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.Resources.Layout
                                                .SearchFormViewMoreLink
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.creators && _vm.creators.totalItemCount
                      ? _c(
                          "div",
                          {
                            staticClass: "gfu-search-form-results__group",
                            attrs: { "data-qa": "search-form:Results" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "gfu-search-form-results__heading",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.Resources.Layout
                                        .SearchFormResultsCreatorsCountLabel
                                    ) +
                                    " "
                                ),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.creators.totalItemCount)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.creators.pagedItems, function (creator) {
                              return _c("search-form-item", {
                                key: `creator-${creator.creatorID}`,
                                staticClass: "_mb-2",
                                attrs: {
                                  url: creator.creatorPageUrl,
                                  title: creator.name,
                                  "thumb-image-url": creator.thumbImageUrl,
                                  "data-qa": `search-form-results:${creator.name}`,
                                },
                              })
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }